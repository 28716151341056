/* eslint-disable */
import { createRouter, createWebHistory } from 'vue-router';

const NotFound = () => import('@/pages/error/NotFound.vue');

// auths pages
const AuthRegister = () => import('@/pages/auths/AuthRegister');
const AuthLogin = () => import('@/pages/auths/AuthLogin');
const AuthLoginECP = () => import('@/pages/auths/AuthLoginECP');
const AuthReset = () => import('@/pages/auths/AuthReset');

const Icons = () => import('@/pages/Icons');

// ESOMP
const Dashboard = () => import('@/pages/Dashboard');

const Counterparties = () => import('@/pages/Counterparties');
const CounterpartiesDetails = () => import('@/pages/CounterpartiesDetails');
const Contracts = () => import('@/pages/Contracts');
const ContractDetails = () => import('@/pages/ContractDetails');
const ContractCreate = () => import('@/pages/ContractCreate');
const ContractPrint = () => import('@/pages/ContractPrint');
const ContractPrintMedorg = () => import('@/pages/medorg/ContractPrint');
const ContractPrintApp = () => import('@/pages/ContractPrintApp');
const ContractPrintAppMedorg = () => import('@/pages/medorg/ContractPrintApp');
const Payments = () => import('@/pages/Payments');

// Control
const UsersList = () => import('@/pages/control/users/UsersList');
const UserEdit = () => import('@/pages/control/users/UserEdit');

const Monitoring = () => import('@/pages/Monitoring');
const MonitoringDetails = () => import('@/pages/MonitoringDetails');

// Postcontrol
const Postcontrol = () => import('@/pages/Postcontrol');
const pcTreatedCases = () => import('@/pages/postcontrol/TreatedCases');
const pcVTMP = () => import('@/pages/postcontrol/VTMP');
const pcKDUServices = () => import('@/pages/postcontrol/KDUServices');
const pcMonitoringDetails = () => import('@/pages/postcontrol/MonitoringDetails');

const Classifiers = () => import('@/pages/Classifiers');

const Protocols = () => import('@/pages/Protocols');
const ProtocolDetails = () => import('@/pages/ProtocolDetails');

const Applications = () => import('@/pages/Applications');
const ApplicationDetails = () => import('@/pages/ApplicationDetails');
const ApplicationEdit = () => import('@/pages/ApplicationEdit');
const ApplicationEditNew = () => import('@/pages/ApplicationEditNew');

// Expert
const DashboardExpert = () => import('@/pages/expert/Dashboard');
const MonitoringExpert = () => import('@/pages/expert/Monitoring');
const MonitoringDetailsExpert = () => import('@/pages/expert/MonitoringDetails');

// Supervisor
const DashboardSupervisor = () => import('@/pages/supervisor/Dashboard');

// Med Organization
const DashboardMedorg = () => import('@/pages/medorg/Dashboard');
const MonitoringMedorg = () => import('@/pages/medorg/Monitoring');
const MonitoringDetailsMedorg = () => import('@/pages/medorg/MonitoringDetails');
const ApplicationsMedorg = () => import('@/pages/medorg/Applications');
const ApplicationEditMedorg = () => import('@/pages/medorg/ApplicationEdit');

const routes = [
  {
    path: '/',
    name: 'e-Densaulyq - Главная',
    component: Dashboard,
    alias: '/home'
  },
  {
    path: '/expert',
    name: 'e-Densaulyq - АРМ Эксперта',
    component: DashboardExpert,
  },
  {
    path: '/supervisor',
    name: 'e-Densaulyq - АРМ Руководителя',
    component: DashboardSupervisor,
  },
  {
    path: '/medorg',
    name: 'e-Densaulyq - МО',
    component: DashboardMedorg,
  },
  {
    path: '/counterparties',
    name: 'e-Densaulyq - Контрагенты',
    component: Counterparties, 
  },
  {
    path: '/counterparties/details/:id',
    name: 'e-Densaulyq - Карточка МО',
    component: CounterpartiesDetails
  },
  {
    path: '/contracts',
    name: 'e-Densaulyq - Договоры',
    component: Contracts, 
  },
  {
    path: '/contracts/details',
    name: 'e-Densaulyq - Просмотр договора',
    component: ContractDetails, 
  },
  {
    path: '/contracts/create',
    name: 'e-Densaulyq - Создание договора',
    component: ContractCreate, 
  },
  {
    path: '/payments',
    name: 'e-Densaulyq - Оплата',
    component: Payments, 
  },
  {
    path: '/protocols',
    name: 'e-Densaulyq - Протоколы ЭКЗ',
    component: Protocols, 
  },
  {
    path: '/protocol/:id',
    name: 'e-Densaulyq - Просмотр протокола ЭКЗ',
    component: ProtocolDetails, 
  },
  {
    path: '/applications',
    name: 'e-Densaulyq - Проекты договоров',
    component: Applications, 
    reload: true,
  },
  {
    path: '/application/details',
    name: 'e-Densaulyq - Просмотр проекта договора',
    component: ApplicationDetails, 
  },
  {
    path: '/application/:id',
    name: 'e-Densaulyq - Редактирование проекта договора',
    component: ApplicationEdit, 
    reload: true,
  },
  {
    path: '/application/edit/:id',
    name: 'e-Densaulyq - Редактирование проекта договора New',
    component: ApplicationEditNew, 
    reload: true,
  },


  {
    path: '/medorg/applications',
    name: 'e-Densaulyq - MO - Проекты договоров',
    component: ApplicationsMedorg, 
    reload: true,
  },
  {
    path: '/medorg/application/:id',
    name: 'e-Densaulyq - MO - Редактирование проекта договора',
    component: ApplicationEditMedorg, 
    reload: true,
  },
  {
    path: '/medorg/application/print/:id',
    name: 'e-Densaulyq - Печатная форма договора МО',
    component: ContractPrintMedorg, 
    reload: true,
  },
  {
    path: '/medorg/application/printapp/:id',
    name: 'e-Densaulyq - Печатной формы',
    component: ContractPrintAppMedorg, 
    reload: true,
  },


  {
    path: '/application/print/:id',
    name: 'e-Densaulyq - Печатная форма договора ФСМС',
    component: ContractPrint, 
    reload: true,
  },
  {
    path: '/application/printapp/:id',
    name: 'e-Densaulyq - Печатная форма',
    component: ContractPrintApp, 
    reload: true,
  },



  {
    path: '/monitoring',
    name: 'e-Densaulyq - Мониторинг качества',
    component: Monitoring, 
  },

  {
    path: '/postcontrol',
    name: 'e-Densaulyq - Постконтроль мониторинга',
    component: Postcontrol, 
  },
  {
    path: '/postcontrol/treatedcases',
    name: 'e-Densaulyq - Постконтроль мониторинга - Пролеченные случаи',
    component: pcTreatedCases, 
  },
  {
    path: '/postcontrol/treatedcases/details/:id',
    name: 'e-Densaulyq - Постконтроль мониторинга - Пролеченные случаи - Просмотр',
    component: pcMonitoringDetails, 
  },
  {
    path: '/postcontrol/vtmp',
    name: 'e-Densaulyq - Постконтроль мониторинга - ВТМП',
    component: pcVTMP, 
  },
  {
    path: '/postcontrol/kduservices',
    name: 'e-Densaulyq - Постконтроль мониторинга - Услуги КДУ вне КПН',
    component: pcKDUServices, 
  },

  {
    path: '/expert/monitoring',
    name: 'e-Densaulyq - АРМ Эксперта - Мониторинг качества',
    component: MonitoringExpert, 
  },
  {
    path: '/medorg/monitoring',
    name: 'e-Densaulyq - MO - Мониторинг качества',
    component: MonitoringMedorg, 
  },
  {
    path: '/monitoring/details/:id',
    name: 'e-Densaulyq - Мониторинг - Пролеченный случай',
    component: MonitoringDetails, 
  },
  {
    path: '/expert/monitoring/details/:id',
    name: 'e-Densaulyq - АРМ Эксперта - Мониторинг - Пролеченный случай',
    component: MonitoringDetailsExpert, 
  },
  {
    path: '/medorg/monitoring/details/:id',
    name: 'e-Densaulyq - MO - Мониторинг - Пролеченный случай',
    component: MonitoringDetailsMedorg, 
  },
  {
    path: '/classifiers',
    name: 'e-Densaulyq - Классификаторы',
    component: Classifiers, 
  },
  {
    path: '/control/userslist',
    name: 'Управление пользователями',
    component: UsersList,
  },
  {
    path: '/control/user/:id',
    name: 'Редактирование пользователя',
    component: UserEdit,
  },




  {
    path: '/auths/auth-register',
    name: 'Регистрация',
    component: AuthRegister
  },
  {
    path: '/auths/auth-login',
    name: 'Войти в систему',
    component: AuthLogin
  },
  {
    path: '/auths/auth-login-ecp',
    name: 'Войти в систему с помощью ЭЦП',
    component: AuthLoginECP
  },
  {
    path: '/auths/auth-reset',
    name: 'Забыл пароль',
    component: AuthReset
  },
  {
    path: '/icons',
    name: 'Icons',
    component: Icons
  },
  { path: '/:pathMatch(.*)*', 
    name: 'Page NotFound', 
    component: NotFound 
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

// this function dynamically change the route title of app and Redirect user to login page if not logged in
router.beforeEach((to, from, next) => {
  document.title = `${to.name} - ЕСОМП`
  const publicRoutes = ['/auths/auth-register', '/auths/auth-login', '/auths/auth-login-ecp', '/auths/auth-reset'];
  const isAuthenticated = localStorage.getItem('eHealthBoardAuthenticated')
  if(publicRoutes.includes(to.path) || isAuthenticated){
    next();
  }else{
    next('/auths/auth-login');
  }
})

export default router
